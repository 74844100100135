import axios from '@axios'

export class GroupBilling {
  constructor() {
  }

  async getAccounting(group_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${group_id}/accounting`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }
  async getBillingSheetProducts(bhseet) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${bhseet.group_id}/packages/${bhseet.pack_id}/occupation-types/${bhseet.occup_id}/travelers/${bhseet.traveler_id}/products`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }
  async updateStatusBillingSheet(group_id, bsheet, status) {
    let stats = {
      "status": status
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`/groups/${group_id}/packages/${bsheet.package_id}/occupation-types/${bsheet.occupation_type_id}/travelers/${bsheet.traveler_type}/status`,stats)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }
  async updateBillingSheetProducts(group_id, bsheet,product) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/groups/${group_id}/packages/${bsheet.pack_id}/occupation-types/${bsheet.occup_id}/travelers/${bsheet.traveler_id}/products`,product)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }
}
