import axios from "@axios";

export class GroupProductService {
  constructor() {}
  async getProducts(group_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${group_id}/products`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async postProduct(group_id, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/groups/${group_id}/products`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updateProduct(group_id, data) {
    let updatedProduct = {
      type: data.type,
      name: data.name,
      amount: data.amount,
      taxes: data.taxes,
      other_taxes: data.other_taxes,
      gst: data.gst,
      id: data.id,
      qst: data.qst,
      net_total: data.net_total,
      gross_total: data.gross_total,
      commission: data.commission,
      commission_gst: data.commission_gst,
      commission_qst: data.commission_qst,
      commission_total: data.commission_total,
      required_deposit_amount: data.required_deposit_amount,
      payment_instruction: data.payment_instruction,
      notes: data.notes,
      pc_voyages_reference_no: data.pc_voyages_reference_no,
      pc_voyages_product_type: data.pc_voyages_product_type,
      pc_voyages_product_supplier: data.pc_voyages_product_supplier,
      pc_voyages_product_desc: data.pc_voyages_product_desc
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/groups/${group_id}/products/${updatedProduct.id}`,
          updatedProduct
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async deleteProduct(group_id, product_id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/groups/${group_id}/products/${product_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
}
