<template>
  <div>
    <b-row class="match-height mt-2">
      <b-col lg="12" md="12">
        <div class="mt-2">
          <h3 class="float-left">Fiches comptables par forfait</h3>
          <b-button variant="success" class="float-right"> Exporter</b-button>
          <!--        <b-button variant="info" class="float-right mr-1"  v-b-modal.add-group-billing> Ajouter </b-button>-->
          <AddModalBsheet :current-group-id="currentGroupId"></AddModalBsheet>
        </div>
      </b-col>
    </b-row>

    <b-card>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center"></div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="groups"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'occupation_type'">
            <b-badge :variant="occupationVariant(props.row.occupation_type)">
              {{ occupationLabel(props.row.occupation_type) }}
            </b-badge>
          </span>

          <span v-if="props.column.field === 'package_name'">
            <b-link @click="editPackage(props.row)">
              {{ props.row.package_name }}
            </b-link>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusLabel(props.row.status) }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'traveler_type'">
            <b-badge>
              {{ ageCategories(props.row.traveler_type.toLowerCase()) }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span v-if="canBeModified">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'group-edit-billing-sheet',
                    params: {
                      group_id: currentGroupId,
                      pack_id: props.row.package_id,
                      occup_id: props.row.occupation_type_id,
                      traveler_id: props.row.traveler_type,
                    },
                  }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus('COMPLETED', props.row)"
                  v-if="
                    props.row.status == 'NOT_STARTED' ||
                    props.row.status == 'IN_PROGRESS'
                  "
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Marquer Complétée</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus('IN_PROGRESS', props.row)"
                  v-if="
                    props.row.status == 'NOT_STARTED' ||
                    props.row.status == 'COMPLETED'
                  "
                >
                  <feather-icon icon="ActivityIcon" class="mr-50" />
                  <span>Marquer En cours</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus('NOT_STARTED', props.row)"
                  v-if="
                    props.row.status == 'IN_PROGRESS' ||
                    props.row.status == 'COMPLETED'
                  "
                >
                  <feather-icon icon="XCircleIcon" class="mr-50" />
                  <span>Marquer Non débutée</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              <b-button
                size="sm"
                variant="flat-primary"
                :to="{
                  name: 'group-edit-billing-sheet',
                  params: {
                    group_id: currentGroupId,
                    pack_id: props.row.package_id,
                    occup_id: props.row.occupation_type_id,
                    traveler_id: props.row.traveler_type,
                  },
                }"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>Consulter</span>
              </b-button>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Indiquer 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import AddModalBsheet from "./AddGroupBillingSheetModal";
import { ref } from "@vue/composition-api";
import { GroupBilling } from "../../services/GroupBilling";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import store from "@/store";
import router from "@/router";

const groupBilling = new GroupBilling();
export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    AddModalBsheet,
    BLink,
  },
  props: {
    currentGroupId: {
      type: String,
      required: true,
    },
    canBeModified: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const groups = ref([]);
    const columns = ref([]);

    const array = [
      {
        label: "Forfait",
        field: "package_name",
      },
      {
        label: "Occupation",
        field: "occupation_type",
      },
      {
        label: "Type de voyageur",
        field: "traveler_type",
      },
      {
        label: "Action",
        field: "action",
      },
    ];

    if (props.canBeModified) {
      array.unshift({
        label: "Statut",
        field: "status",
      });
    }

    columns.value = array;

    const fetchTravelers = () => {
      groupBilling.getAccounting(props.currentGroupId).then((response) => {
        groups.value = response.data.associations;
      });
    };
    fetchTravelers();
    const changeStatus = async (status, bSheet) => {
      try {
        let response = await groupBilling.updateStatusBillingSheet(
          props.currentGroupId,
          bSheet,
          status
        );
        fetchTravelers();
        store.commit("app-groups/setAccountingAssociationStatus", {
          association: bSheet,
          status: status,
        });
        displaySuccessMessage("Fiche comptable modifiée");
      } catch (e) {
        displayErrorMessage("La modification a échoué");
      }
    };

    const editPackage = (selectedPackage) => {
      router.push({
        name: "group-edit-billing-sheet",
        params: {
          group_id: props.currentGroupId,
          pack_id: selectedPackage.package_id,
          occup_id: selectedPackage.occupation_type_id,
          traveler_id: selectedPackage.traveler_type,
        },
      });
    };

    return {
      groups,
      changeStatus,
      columns,
      editPackage,
    };
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      searchTerm: "",
      typeOptions: ["TRANSPORT", "all_inclusive"],
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      planOptions: [],
    };
  },
  computed: {
    occupationVariant() {
      const statusColor = {
        SINGLE: "light-danger",
        DOUBLE: "light-success",
        TRIPE: "light-warning",
        QUADRUPLE: "light-primary",
      };
      return (status) => statusColor[status];
    },

    occupationLabel() {
      const statusLabel = {
        SINGLE: "Simple",
        DOUBLE: "Double",
        TRIPLE: "Triple",
        QUADRUPLE: "Quadruple",
      };
      return (status) => statusLabel[status];
    },

    statusVariant() {
      const statusColor = {
        NOT_STARTED: "danger",
        IN_PROGRESS: "info",
        COMPLETED: "success",
      };
      return (status) => statusColor[status];
    },

    statusLabel() {
      const statusLabel = {
        NOT_STARTED: "Non débutée",
        IN_PROGRESS: "En cours",
        COMPLETED: "Complétée",
      };
      return (status) => statusLabel[status];
    },

    statusIcons() {
      const statusIcons = {
        NOT_STARTED: "DropletIcon",
        IN_PROGRESS: "SunIcon",
      };
      return (status) => statusIcons[type];
    },
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return (character) =>
        Math.round(parseInt(character.slice(-1), UUID_BASE) / BASE);
    },
    avatarColors() {
      const avatarColors = {
        1: "primary",
        2: "dark",
        3: "secondary",
        4: "success",
        5: "danger",
        6: "warning",
        7: "info",
        8: "secondary",
      };
      return (character) => avatarColors[character];
    },
    ageCategories() {
      let ageCategories = [...groupCreationConfig.ageCategories];
      let categories = {};
      ageCategories.forEach(
        (x) => (categories[x.value.toLowerCase()] = x.text)
      );
      return (category) => categories[category];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
