<template>
  <validation-observer ref="simpleRules">
    <form>
      <div class="row">
        <div class="col-md-12">
          <p class="text-muted text-uppercase mb-0">dépot & note</p>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              value="PRODUCT"
            >
              Produit
            </b-form-radio>
            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              value="ADMIN_FEE_IN"
            >
              Frais administratif - RECEVABLE
            </b-form-radio>
            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              value="ADMIN_FEE_OUT"
            >
              Frais administratif - PAYABLE
            </b-form-radio>
          </div>
        </div>
        <div class="col-md-12">
          <p class="text-muted text-uppercase">dépot & note</p>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Nom de produit</label>
            <validation-provider
              #default="{ errors }"
              name="Nom de produit"
              rules="required"
            >
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Saisir le nom de produit ..."
                v-model="formData.name"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
              <small v-if="pcVoyagesEnabled" >
                <em>Le nom de produit doit correspondre à celui dans PC Voyages</em>
              </small>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <div v-if="pcVoyagesEnabled" class="col-md-12">
          <p class="text-muted text-uppercase">PC Voyages</p>
        </div>
        <div class="col-md-3">
          <div v-if="pcVoyagesEnabled" class="form-group">
            <label>Numéro Tour Opérateur</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Entrer le code du produit"
              v-model="formData.pc_voyages_reference_no"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div v-if="pcVoyagesEnabled" class="form-group">
            <label>Type de produit</label>
            <v-select
              :options="productTypes"
              class="form-control-sm"
              placeholder="Sélectionner le type"
              v-model="formData.pc_voyages_product_type"
              :reduce="type => parseInt(type.code)"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div v-if="pcVoyagesEnabled" class="form-group">
            <label>Fournisseur du produit</label>
            <v-select
              :options="suppliers"
              class="form-control-sm"
              placeholder="Code du fournisseur"
              taggable
              v-model="formData.pc_voyages_product_supplier"
              :reduce="supplier => supplier.code ? supplier.code : supplier.label"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div v-if="pcVoyagesEnabled" class="form-group">
            <label>Description</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Description optionnelle"
              v-model="formData.pc_voyages_product_desc"
            />
          </div>
        </div>
        
        <div class="col-12">
          <p class="text-muted text-uppercase">Montant du produit</p>
        </div>
        <div class="col-md-6">
          <p><strong>Détail du prix de vente</strong></p>
          <div class="form_sale">
            <validation-provider
              #default="{ errors }"
              name="Montant total avant taxes"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Montant total avant taxes
                </label>
                <div class="col">
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model.number="formData.amount"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="Taxes "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Taxes
                </label>
                <div class="col">
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model.number="formData.taxes"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="Autres taxes"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Autres taxes
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.other_taxes"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TPS (sur montant avant taxes) "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TPS (sur montant avant taxes)
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.gst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TVQ (sur montant avant taxes)"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TVQ (sur montant avant taxes)
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.qst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name=" Montant total Brut (Prix de vente) "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Montant total Brut (Prix de vente)
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.gross_total"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name=" Montant total Net (Prix de vente au NET) "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Montant total Net (Prix de vente au NET)
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.net_total"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="col-md-6" v-if="formData.type === 'PRODUCT'">
          <p><strong>Commission</strong></p>
          <div class="form_sale">
            <validation-provider
              #default="{ errors }"
              name="Commission "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Commission
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TPS "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TPS
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_gst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TVQ "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TVQ
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_qst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name=" Total commission à recevoir "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Total commission à recevoir
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_total"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="col-md-6" v-if="formData.type === 'ADMIN_FEE_IN'">
          <p><strong>Frais administratif</strong></p>
          <div class="form_sale">
            <validation-provider
              #default="{ errors }"
              name="Montant à recevoir"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Montant à recevoir
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TPS "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TPS
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_gst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TVQ "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TVQ
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_qst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="Total montant à recevoir"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Total montant à recevoir
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_total"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="col-md-6" v-if="formData.type === 'ADMIN_FEE_OUT'">
          <p><strong>Frais administratif</strong></p>
          <div class="form_sale">
            <validation-provider
              #default="{ errors }"
              name="Montant payable à TDC (état de compte)"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Montant payable à TDC (état de compte)
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TPS "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TPS
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_gst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="TVQ "
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  TVQ
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_qst"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="Total montant payable à TDC (état de compte)"
              rules="required"
            >
              <div role="group" class="form-row form-group">
                <label class="col-lg-8 col-4 col-form-label col-form-label-sm">
                  Total montant payable à TDC (état de compte)
                </label>
                <div class="col">
                  <input
                    type="number"
                    v-model="formData.commission_total"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </div>
                <div class="col-12">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="col-md-12">
          <p class="text-muted text-uppercase">dépot & note</p>
          <div class="row">
            <div class="col-md-3">
              <validation-provider
                #default="{ errors }"
                name="Montant de dépot "
                rules="required"
              >
                <div class="form-group">
                  <label>Montant de dépot</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="formData.required_deposit_amount"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                  <div class="col-12">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </div>
              </validation-provider>
            </div>
            <div class="col-md-3">
              <validation-provider
                #default="{ errors }"
                name="Comment payer dépot "
                rules="required"
              >
                <div class="form-group">
                  <label>Comment payer dépot</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="formData.payment_instruction"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                  <div class="col-12">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </div>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Note de produit</label>
                <textarea
                  name=""
                  class="form-control form-control-sm"
                  cols="30"
                  rows="3"
                  v-model="formData.notes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormRadio } from "bootstrap-vue";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";
import vSelect from "vue-select";

export default {
  name: "FormProduct",
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    vSelect,
  },
  props: ["currentItemProduct"],
  data() {
    return {
      formData: this.currentItemProduct
        ? this.currentItemProduct
        : { type: "PRODUCT" },
    };
  },
  computed: {
    pcVoyagesEnabled() {
      return this.$store.state[AUTH_STORE_NAMESPACE].orgSettings
        .pcvoyages_enable;
    },
    productTypes() {
      return [
        { code: "0", label: "Vol" },
        { code: "1", label: "Forfait" },
        { code: "2", label: "Hotel" },
        { code: "3", label: "Auto" },
        { code: "4", label: "Assurances" },
        { code: "5", label: "Circuit" },
        { code: "6", label: "Train" },
        { code: "7", label: "Divers" },
        { code: "8", label: "Réservation" },
        { code: "9", label: "Autobus" },
        { code: "10", label: "Vol nollisé" },
        { code: "11", label: "Croisière" },
        { code: "12", label: "Conslidateur" },
        { code: "14", label: "Frais service" },
        { code: "16", label: "Ski" },
        { code: "17", label: "Golf" },
        { code: "18", label: "Vol Combiné" },
        { code: "19", label: "Spectacle" },
        { code: "20", label: "Forfait +" },
        { code: "21", label: "Frais Admin." },
        { code: "22", label: "Ajustement" },
        { code: "30", label: "Villas" },
        { code: "35", label: "Voyages 1 journée" },
        { code: "40", label: "Transfert" },
      ]
    },
    suppliers() {
      return [
        { code: "AEO", label: "Les Aventures Eoliennes" },
        { code: "AIRCRE", label: "Air Creebec" },
        { code: "AIRNET", label: "Air Nets International" },
        { code: "ALI", label: "Alio" },
        { code: "AMSTAR", label: "AMSTARDMC" },
        { code: "ART", label: "Artistours" },
        { code: "ASG", label: "Assurance Rbc Groupe" },
        { code: "ATS", label: "AIR TRANSAT" },
        { code: "AUS", label: "Ausrian Airline" },
        { code: "AUT", label: "Auto Europe" },
        { code: "AUTEXC", label: "Autocar Excellence Inc." },
        { code: "AVT", label: "Assurance Voyage Transat" },
        { code: "BED", label: "BEDSONLINE" },
        { code: "BELL", label: "BELL" },
        { code: "BOO", label: "Boomerang& Intair" },
        { code: "BPH", label: "Bahia Principe Hotel" },
        { code: "BUS", label: "Les Tours Du Vieux Quebec" },
        { code: "CAN", label: "Canandes Int'l Tours Inc." },
        { code: "CAR", label: "Caribe Sol" },
        { code: "CAS", label: "Cassis (voyages)" },
        { code: "CAV", label: "Carnival Cruise" },
        { code: "CCA", label: "Certificat Cadeau Airmiles" },
        { code: "CCE", label: "Cadeau Clients Élysée" },
        { code: "CCV", label: "Certificat Cadeau Club Voyages" },
        { code: "CD7", label: "Club D-7" },
        { code: "CER", label: "Traducteur" },
        { code: "CHA", label: "Tours Chanteclerc" },
        { code: "CIT", label: "Compagnie Italienne Tourism" },
        { code: "CLU", label: "Club Med" },
        { code: "CLUBA", label: "Club Aventure" },
        { code: "CNT", label: "Connection Tours" },
        { code: "CON", label: "Conquest Vacation" },
        { code: "COV", label: "COVID19- IMPORTANT - À LIRE" },
        { code: "COW", label: "Continental Web" },
        { code: "CPT", label: "Carte Postale.Tour Chanteclers" },
        { code: "CUN", label: "Cunard Line" },
        { code: "CUR", label: "Tours Cure-vac" },
        { code: "CVE", label: "Club Voyages Elysee" },
        { code: "CVEH", label: "Club Voyages Elysee-hotel" },
        { code: "CVENET", label: "VENTE NET" },
        { code: "CVG", label: "Club Voyages Groupes" },
        { code: "DIV", label: "Produits touristiques divers" },
        { code: "E&O", label: "Erreurs et Omissions" },
        { code: "ENC", label: "Encore Cruise" },
        { code: "ENCUSD", label: "Encore Cruise" },
        { code: "EXC", label: "The Excellence Group" },
        { code: "EXC(CAD)", label: "The Excellence Group (CAD)" },
        { code: "EXP", label: "Express Air" },
        { code: "EXT", label: "Exotik Tours" },
        { code: "FIR", label: "First Air" },
        { code: "FIVES", label: "The Fives Hotels & Residences" },
        { code: "FLE", label: "Flextour" },
        { code: "FLYPORTER", label: "Fly Porter" },
        { code: "FORFAT", label: "La Forfaiterie" },
        { code: "FRLKVOLDIV", label: "Farelink" },
        { code: "FSF", label: "Honoraire Professionnel" },
        { code: "G&CIE", label: "Groupes et Cie" },
        { code: "GLC", label: "Gabriel Lafleur" },
        { code: "GMA", label: "Tour Mondial" },
        { code: "GOL", label: "Golf Holidays" },
        { code: "GPUSD", label: "Palladium Hotel Group" },
        { code: "GRO", label: "Grossiste" },
        { code: "GVQ", label: "Groupe Voyages Québe" },
        { code: "HAI", label: "Tours Hai International" },
        { code: "HAL", label: "Holland America Line" },
        { code: "HIT", label: "Hi Tours" },
        { code: "IDE", label: "Vacances Ideal" },
        { code: "INC", label: "Incursions Voyages" },
        { code: "INSVAC", label: "Insight Vacations (can) Ltd." },
        { code: "INT", label: "Intair Transit" },
        { code: "IR", label: "Island Routes" },
        { code: "JAD", label: "Jade Tours" },
        { code: "JOL", label: "Jolivac Inc." },
        { code: "KIL", label: "Merika Tours" },
        { code: "MAE", label: "Vacances Maestro" },
        { code: "MAI", label: "Tours Maison" },
        { code: "MAIL CV", label: "MAIL CV" },
        { code: "MAN", label: "Manuvie" },
        { code: "MIM", label: "Vacances Mimosa" },
        { code: "NCL", label: "Norwegian Cruise Line - Direct" },
        { code: "NEO", label: "Néo Tours (vacances)" },
        { code: "NOL", label: "Nolitours" },
        { code: "OCEA", label: "Oceania Cruise" },
        { code: "OK", label: "Czech Airline" },
        { code: "OPC", label: "Fonds D'indemnisation" },
        { code: "OPS", label: "Fond Indemnisation Usd" },
        { code: "PAL", label: "PAL Airlines Ltd." },
        { code: "PAS", label: "Pascan Aviation" },
        { code: "PCL", label: "Princess  Cruises Line" },
        { code: "PCU", label: "Princess  Cruises Line" },
        { code: "PEU", label: "Europ Auto" },
        { code: "PIE", label: "Air St-pierre" },
        { code: "PRO", label: "Provincial Airlines" },
        { code: "QUE", label: "Autobus La Quebecoise" },
        { code: "RAI", label: "Rail Europ" },
        { code: "RBC", label: "Assurances Rbc" },
        { code: "REC", label: "Réservations Seulement CAD" },
        { code: "REL", label: "Old Republic Canada" },
        { code: "REN", label: "Renault Canada" },
        { code: "REV", label: "transat decouverte et merika" },
        { code: "ROK", label: "Rockymountainer" },
        { code: "RSS", label: "Regent Seven Sese Cruises" },
        { code: "RSSUSD", label: "Regent Seven Seas USD" },
        { code: "SAN", label: "Sandals Vacation" },
        { code: "SANUSD", label: "Sandals Vacation USD" },
        { code: "SHA", label: "Shangrila Tours" },
        { code: "SIG", label: "Signature (vacances)" },
        { code: "SIM", label: "Simpatico Tours" },
        { code: "SIN", label: "Sinorama" },
        { code: "SKY", label: "Skylink Voyages" },
        { code: "SOL", label: "Tours Chanteclers/solbec" },
        { code: "SOLMELIA", label: "Sol Melia Cuba" },
        { code: "STA", label: "Standard Tours" },
        { code: "SUL", label: "Sultana Tours" },
        { code: "SUN", label: "Vac. Sunwing Et Ou  Signature" },
        { code: "SWG", label: "Sunwing" },
        { code: "TAAP", label: "Expedia TAAP" },
        { code: "TAAP(USD)", label: "EXPEDIA TAAP USD" },
        { code: "TAAPUSD", label: "USD EXPEDIA TAAP" },
        { code: "TDC", label: "Transat Distribution Canada" },
        { code: "TERA", label: "Terratours" },
        { code: "TMR", label: "Vacances Tmr" },
        { code: "TOU", label: "Tourika expérience urbaine" },
        { code: "TRA", label: "Tours Amérique" },
        { code: "TRAVELBRAN", label: "Travelbrands" },
        { code: "TS", label: "Air transat" },
        { code: "TSO", label: "Tours Solazur" },
        { code: "TUR", label: "Tuquie V.i.p. Tours" },
        { code: "UJV", label: "Ultimate Jet Vacations" },
        { code: "VAC", label: "Vacances Air Canada" },
        { code: "VAP", label: "Vacances Préférence" },
        { code: "VAR", label: "Varaplaya Tours" },
        { code: "VAT", label: "Vacances Transat" },
        { code: "VAX", label: "Vax Vacation" },
        { code: "VAXCAD", label: "vax" },
        { code: "VCORI", label: "Voyages Circuit Oriental Inc." },
        { code: "VIA", label: "Via Rail Web" },
        { code: "VIAT", label: "Viator Excursion" },
        { code: "VISACVE", label: "Visa Club Voyages Élysée" },
        { code: "VMAR", label: "VISITE MAROC" },
        { code: "VRCAN", label: "CanaDream" },
        { code: "VSQ", label: "Vacances Sunquest" },
        { code: "WAC", label: "Air Canada" },
        { code: "WAL", label: "Air Labrador" },
        { code: "WCJ", label: "Canjet" },
        { code: "WDT", label: "Walt Disney Travel" },
        { code: "WDYFIT", label: "WALT DYSNEY CANADIEN" },
        { code: "WEE", label: "Weekend Express" },
        { code: "WGB", label: "Wingbuddy" },
        { code: "WGO", label: "Jetsgo" },
        { code: "WQE", label: "Québecair Express" },
        { code: "WWJ", label: "Westjet" },
      ];
    },
  },
};
</script>

<style scoped>
.demo-inline-spacing {
  margin: -8px 0 15px 0;
}
[dir] .v-select .vs__dropdown-toggle {
  padding: 0.025rem 0.5rem !important;
 }
</style>
