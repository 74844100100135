<template>
  <b-modal ref="edit-product-modal" :id="'edit-product-' + currentProd.id" :title="`Modification du produit : `+currentProd.name "
           ok-title="Sauvegarder"
           cancel-variant="outline-secondary"
           cancel-title="Annuler"
           size="lg"
         @ok.prevent='validationForm'>
    <FormProduct ref="formUpdate"  :current-item-product="currentProd"></FormProduct>
  </b-modal>
</template>
<script>
import {reactive} from "@vue/composition-api/dist/vue-composition-api";
import {ValidationProvider, ValidationObserver,localize} from 'vee-validate';
import FormProduct from './FormProduct.vue'
import {required} from '@validations'
export default {
name: "editGroupProductModal",
  props:['currentGroupId','currentProduct'],
  components: {
    ValidationProvider,
    ValidationObserver,
    FormProduct
  },
  setup(props, {emit}) {
    let currentProd = reactive({...props.currentProduct})
    const submitUpdateGroupProduct = (product) => {
      currentProd=product
      emit('submitUpdateGroupProduct', currentProd)
    }
    return {
      submitUpdateGroupProduct,
      currentProd
    }
    },
  mounted() {
    this.blankProductObj = { ...this.productobj };
  },
  data() {
    return {
      required,
      locale: 'fr',
    }
  },
  methods: {
    validationForm() {
      localize(this.locale)
      this.$refs.formUpdate.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitUpdateGroupProduct(this.$refs.formUpdate.$data.formData);
          this.$refs["edit-product-modal"].hide()
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
