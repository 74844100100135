<template>
  <b-modal ref="add-product-modal" id="add-product"
           title="Création du produit"
           ok-title="Sauvegarder"
           cancel-variant="outline-secondary"
           cancel-title="Annuler"
           size="lg"
           @cancel=""
           @ok.prevent='validationForm' >
    <FormProduct ref="formProductCreate" ></FormProduct>
  </b-modal>
</template>
<script>
import {GroupProductService} from '../../../services/GroupProductService.js'
import {ValidationProvider, ValidationObserver,localize} from 'vee-validate'
import {required} from '@validations'
import {reactive} from "@vue/composition-api/dist/vue-composition-api";
import FormProduct from './FormProduct.vue'
export const groupProduct = new GroupProductService();
export default {
  name: "addGroupProductModal",
  components: {
    ValidationProvider,
    ValidationObserver,
    FormProduct
  },
  props: ['currentGroupId'],
  setup(props, {emit}) {
    let productobj = reactive({});
    const submitGroupProduct = (product) => {
      productobj=product;
      emit('submitGroupProduct', productobj)
    }
    return {
      submitGroupProduct,
      productobj,
    }
  },
  data() {
    return {
      required,
      locale: 'fr',
      blankProductObj:{}
    }
  },
  mounted() {
    this.blankProductObj = { ...this.productobj };
  },
  methods: {
    validationForm() {
      localize(this.locale)
      this.$refs.formProductCreate.$refs.simpleRules.validate().then(success => {
        if (success) {
         this.submitGroupProduct(this.$refs.formProductCreate.$data.formData);
          this.$refs["add-product-modal"].hide()
          this.productobj = { ...this.blankProductObj };
        }
      })
    },
  },
}

</script>

<style scoped>

</style>
