<template>
  <b-modal       id="add-group-billing"
                 title="Nouvelle fiche comptable"
                 ok-title="Sauvegarder"
                 cancel-variant="outline-secondary"
                 cancel-title="Annuler"
                  >
    <form action="">
      <div class="form-group">
        <label >Forfait</label>
        <v-select
          id="group-type"
          :options="packages"
          v-model="selectedPackage"
          label="name"
          :reduce="option => option"
        />
      </div>
      <div class="form-group">

        <label>Occupation</label>
        <v-select
            :disabled="selectedPackage==null"
          :options="selectedPackage.occupation_types"
          v-model="selectedOccupation"
          class="w-100 text-info"
          label="type"
          :reduce="val => val.type"

        />
      </div>
      <div class="form-group">
        <label>Type de voyageur</label>
        <v-select
          :options="selectedPackage.occupation_types"
          class="w-100 text-info"
          label="type"
          :reduce="val => val"

        />
      </div>
    </form>
  </b-modal>
</template>
<script>
import {reactive, ref} from "@vue/composition-api/dist/vue-composition-api";
import {GroupPackagesService} from '../../services/GroupPackagesService'
import BCardCode from "@core/components/b-card-code";
const groupPackage=new GroupPackagesService;
import {
  BButton,
  BCardText,
  BCol,
  BForm, BFormCheckbox, BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput, BFormInvalidFeedback, BFormRadio, BFormTextarea,
  BInputGroup, BInputGroupPrepend,
  BModal,
  BRow, BTab, BTabs
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {groupProduct} from "@/views/groups/group/GroupBilling/GroupProduct/GroupProduct";
export default {
  name: "AddGroupBillingSheetModal",
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    BFormRadio
  },
  props:['currentGroupId'],
  setup(props) {
    const packages = ref([])
    const fetchpackages = () => {
      groupPackage.getPackages(props.currentGroupId).then(response => {
        packages.value = response.data;
      })
    }
    fetchpackages()
    return {
      packages
    }
  },
  data() {
    return {
      selectedPackage: {
        occupation_type: []
      },
      selectedOccupation: {
        occupation_type_pricing: []
      },
      selectedTraveler: {
        traveler_types: []
      },
    }
  },
}

</script>

<style scoped>

</style>
