<template>
  <GroupBillingSheets
    v-if="group"
    :can-be-modified="
      canViewBasedOnRole(currentRole, [
        availableRoles.ORGANIZATION_ADMIN.role,
        availableRoles.ORGANIZATION_COORDINATOR.role,
      ])
    "
    :current-group-id="group.id"
    :current-packages="packages"
  />
</template>

<script>
import GroupBillingSheets from "./GroupBilling/GroupBillingSheets.vue";
import Roles from "@/modules/authnz/models/role";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";

export default {
  components: { GroupBillingSheets },
  methods: {
    canViewBasedOnRole(currentRole, allowedRoles) {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles);
    },
  },
  computed: {
    availableRoles() {
      return Roles;
    },
    currentRole() {
      return this.$store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;
    },
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    packages() {
      return this.$store.state["app-groups"]?.packages;
    },
  },
};
</script>
