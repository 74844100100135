<template>
  <div>
    <b-row class="match-height mt-2">
      <b-col lg="12" md="12">
        <div class="mt-2">
          <h3 class="float-left">Produits</h3>
          <b-button
            v-if="canBeModified"
            variant="info"
            class="float-right mr-1"
            v-b-modal.add-product
          >
            Ajouter</b-button
          >
          <addProductModal
            :current-group-id="currentGroupId"
            @submitGroupProduct="submitGroupProduct"
          ></addProductModal>
        </div>
      </b-col>
    </b-row>
    <b-card>
      <vue-good-table
        :columns="columns"
        :rows="products"
        :search-options="{
          enabled: false,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"
      >
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <span v-else-if="props.column.field === 'type'">
            {{ productTypeLabel(props.row.type) }}
          </span>

          <span v-else-if="props.column.field === 'action'">
            <span v-if="canBeModified">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item v-b-modal="'edit-product-' + props.row.id">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>
                <edit-product-modal
                  :current-group-id="currentGroupId"
                  :currentProduct="props.row"
                  @submitUpdateGroupProduct="submitUpdateGroupProduct"
                ></edit-product-modal>
                <b-dropdown-item
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="deleteProduct(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Indiquer 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import { ref } from "@vue/composition-api";
import addProductModal from "./addGroupProductModal";
import editProductModal from "./editGroupProductModal";
import { GroupProductService } from "../../../services/GroupProductService.js";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import store from "@/store";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";

export const groupProduct = new GroupProductService();

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    addProductModal,
    editProductModal,
  },
  directives: {
    Ripple,
  },
  props: {
    currentGroupId: {
      type: String,
      required: true,
    },
    currentProduct: {
      type: Object,
    },
    canBeModified: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const { refOf, forceUpdate } = useApplicationContext();
    const products = ref([]);
    let columns = [];
    columns.push({
      label: "Nom",
      field: "name",
    });

    const pcVoyagesEnabled =
      store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;
    if (pcVoyagesEnabled) {
      columns.push({
        label: "Description",
        field: "pc_voyages_product_desc",
      });
    }

    columns.push({
      label: "Type",
      field: "type",
    });

    if (props.canBeModified) {
      columns.push({
        label: "Action",
        field: "action",
      });
    }

    const fetchProducts = () => {
      groupProduct.getProducts(props.currentGroupId).then((response) => {
        products.value = response.data;
      });
    };
    fetchProducts();
    const submitGroupProduct = async (product) => {
      try {
        let response = await groupProduct.postProduct(
          props.currentGroupId,
          product
        );
        let match = response.headers.location.split("/");
        let product_id = match[7];
        product.id = product_id;
        products.value.push(product);
        displaySuccessMessage("Vous avez bien ajouter un produit");
      } catch (e) {
        console.error(e);
        displayErrorMessage("La sauvegarde a échoué");
      }
    };
    const submitUpdateGroupProduct = async (productUpdate) => {
      try {
        let response = await groupProduct.updateProduct(
          props.currentGroupId,
          productUpdate
        );
        fetchProducts();
        displaySuccessMessage("Vous avez bien modifié le produit");
      } catch (e) {
        console.error(e);
        displayErrorMessage("La modification a échoué");
      }
    };
    return {
      columns,
      submitGroupProduct,
      submitUpdateGroupProduct,
      products,
      fetchProducts,
    };
  },
  data() {
    return {
      pageLength: 5,
      searchTerm: "",
    };
  },
  methods: {
    deleteProduct(id) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          groupProduct
            .deleteProduct(this.currentGroupId, id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Supprimé!",
                text: "Votre produit a été supprimé.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.fetchProducts();
            });
        }
      });
    },
  },
  computed: {
    productTypeLabel() {
      const productTypes = {
        PRODUCT: "Produit",
        ADMIN_FEE_IN: "Frais administratif (recevable)",
        ADMIN_FEE_OUT: "Frais administratif (payable)",
      };
      return (type) => productTypes[type];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
